import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { colors } from '../clients/styles/theme'
import { PinkButton } from '../clients/components/atoms/Button'
import Link from 'next/link'
import { logClickEvent } from '../clients/firebase/analytics'
import { useViewEvent } from '../clients/firebase/analytics'
import {
  getGameTitleDisplayName,
  profileCardGameTitles,
} from '../clients/firebase/firestore/GameTitle'
import { getDb } from '../clients/firebase/helpers'
import { ja } from 'date-fns/locale'
import { formatDistanceToNow, fromUnixTime } from 'date-fns'

import {
  Bosyu,
  COLLECTION_NAME_BOSYUS,
} from '../clients/firebase/firestore/Bosyu'
import { GetServerSideProps } from 'next'
import {
  COLLECTION_NAME_USERS,
  Users,
} from '../clients/firebase/firestore/Users'
import { COLLECTION_NAME_ROOMS, Room } from '../clients/firebase/firestore/Room'
import {
  COLLECTION_NAME_PROFILES,
  Profiles,
} from '../clients/firebase/firestore/Profiles'
import {
  COLLECTION_PICKED_ITEMS,
  PickedItem,
} from '../clients/firebase/firestore/PickedItem'
import { Rank, getRankDisplayName } from '../clients/models/types/Rank'
import {
  GameMode,
  getGameModeDisplayName,
} from '../clients/models/types/GameMode'
import {
  getPlatformDisplayName,
  Platform,
} from '../clients/models/types/Platform'
import {
  getVoiceChatDisplayName,
  VoiceChat,
} from '../clients/models/types/VoiceChat'
import { useLocale } from '../clients/hooks/useLocale'
import Image from 'next/image'
import { GlobalNav } from '../clients/components/organisms/GlobalNav'
import { Footer } from '../clients/components/molecules/Footer'
import { AppBanner } from '../clients/components/organisms/Banner/AppBanner'
import { TOP_PAGE_TEMPLATE } from '../config'

type Props = {
  homeBosyus: Bosyu[]
  homeRooms: Room[]
  homeNewUsers: Users[]
  homeNewProfiles: Profiles[]
  homePickedItems: PickedItem[]
}

export const Home: React.FC<Props> = ({
  homeBosyus,
  homeRooms,
  homeNewUsers,
  homeNewProfiles,
  homePickedItems,
}) => {
  useViewEvent('HomeView')
  const { t } = useLocale()
  const onClickCreateBosyu = useCallback(() => {
    logClickEvent('HomeCreateBosyuClick')
  }, [])
  const onClickCreateProfile = useCallback(() => {
    logClickEvent('HomeCreateProfileCardClick')
  }, [])

  const gameIcons = profileCardGameTitles.slice(0, 16).map((game) => {
    return (
      <GameIcon
        imgUrl={`https://storage.googleapis.com/nadare-production-static-images/static/images/gameTitles/icn_${game}.png`}
        key={game}
      ></GameIcon>
    )
  })
  const platformText = (platform: string) => {
    switch (platform) {
      case 'any':
        return 'プラットフォームなんでも！'
      default:
        return getPlatformDisplayName(platform as Platform) + 'の人！'
    }
  }
  const voiceChatText = (voiceChat: string) => {
    switch (voiceChat) {
      case 'any':
        return 'VCどちらでも'
      default:
        return 'VC' + getVoiceChatDisplayName(voiceChat as VoiceChat)
    }
  }

  return (
    <div>
      <GlobalNav />
      <AppBanner />
      <FVContainer>
        <H1Text>
          <H1TextSpan>{t.ゲーム友達を募集するならgamee}</H1TextSpan>
          <LogoContainer>
            <Image
              src="/static/images/icn_gamee_logo.png"
              alt="gamee"
              width={202}
              height={58}
              unoptimized={true}
            />
          </LogoContainer>
        </H1Text>
        <GameTitlesContainer>
          <Attention>{t.対応ゲーム近日追加予定}</Attention>
          <GameIconsContainer>
            {gameIcons}
            <GameIcon imgUrl="/static/images/icn_coming_soon.png" />
          </GameIconsContainer>
        </GameTitlesContainer>
      </FVContainer>
      <AboutGameeContainer>
        <AboutGamee>
          <AboutGameeItem>
            {t.オンラインゲームの自己紹介カードが作成編集できる}
          </AboutGameeItem>
          <AboutGameeItem>
            {t.今遊べるゲーム友達を自動で募集できる}
          </AboutGameeItem>
          <AboutGameeItem>{t.ゲーム募集掲示板で友達が探せる}</AboutGameeItem>
        </AboutGamee>
      </AboutGameeContainer>
      <Container>
        <Spacer />
        <SectionContainer>
          <SectionHeader>{t.自己紹介カードテンプレート}</SectionHeader>
          <ProfileCardsContainer>
            {TOP_PAGE_TEMPLATE.map((template) => {
              return (
                <ProfileTemplateItem key={template.imagePath}>
                  <Link
                    href={template.link}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Image
                      width="180"
                      height="120"
                      unoptimized={true}
                      loading="lazy"
                      alt={template.alt}
                      src={template.imagePath}
                      style={{
                        width: '180px',
                        height: '120px',
                        objectFit: 'cover',
                      }}
                    />
                    <ProfileGameLogo>
                      <ProfileGameLogoContainer>
                        <Image
                          src={`https://storage.googleapis.com/nadare-production-static-images/static/images/gameTitles/icn_${template.game}.png`}
                          alt={`${template.game}`}
                          width={20}
                          height={20}
                          unoptimized={true}
                          loading="lazy"
                          style={{
                            overflow: 'hidden',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            objectFit: 'cover',
                          }}
                        />
                      </ProfileGameLogoContainer>
                      <ProfileGameTitleText>
                        {getGameTitleDisplayName(template.game)}
                      </ProfileGameTitleText>
                    </ProfileGameLogo>
                  </Link>
                </ProfileTemplateItem>
              )
            })}
          </ProfileCardsContainer>
          <SectionContainer>
            <Link
              href={'/me/profiles/create'}
              style={{
                textDecoration: 'none',
              }}
            >
              <PrimaryButton onClick={onClickCreateProfile}>
                {t.もっと見る}
              </PrimaryButton>
            </Link>
          </SectionContainer>
        </SectionContainer>
        <Spacer />
        <SectionContainer>
          <SectionHeader>{t.性格診断}</SectionHeader>
          <Spacer />
          <Link href={'/shindan/'}>
            <Image
              width="1196"
              height="620"
              unoptimized={true}
              loading="lazy"
              src="/static/images/img_shindan.png"
              alt="gameeゲーマータイプ性格診断"
            />
          </Link>
          <SectionContainer>
            <ButtonDescriptionText>
              {t.サービスをご利用いただくにはSNSログインが必要です}
            </ButtonDescriptionText>
            <Link
              href={'/shindan/'}
              style={{
                textDecoration: 'none',
              }}
            >
              <PrimaryButton>{t.性格診断を始める}</PrimaryButton>
            </Link>
          </SectionContainer>
        </SectionContainer>
        <Spacer />
        <SectionContainer>
          <SectionHeader>{t.新着の自己紹介カード}</SectionHeader>
          <ProfileCardsContainer>
            {homeNewProfiles.map((profile) => {
              return (
                <ProfileCardItem key={profile.imgId}>
                  <Link
                    href={`/profiles/${profile.imgId}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <ProfileCard
                      onClick={() => {
                        logClickEvent('HomeUserProfileClick', {
                          profileId: profile,
                        })
                      }}
                    >
                      <Image
                        alt={profile.name + 'さんの自己紹介カード'}
                        src={`https://firebasestorage.googleapis.com/v0/b/nadare-production.appspot.com/o/${profile.imgId}.png?alt=media`}
                        width="180"
                        height="120"
                        unoptimized={true}
                        loading="lazy"
                        style={{
                          width: '180px',
                          height: '120px',
                          objectFit: 'cover',
                        }}
                      />
                    </ProfileCard>
                  </Link>
                </ProfileCardItem>
              )
            })}
          </ProfileCardsContainer>
        </SectionContainer>
        <SectionContainer>
          <ButtonDescriptionText>
            {t.サービスをご利用いただくにはSNSログインが必要です}
          </ButtonDescriptionText>
          <Link
            href={'/me/profiles/create'}
            style={{
              textDecoration: 'none',
            }}
          >
            <PrimaryButton onClick={onClickCreateProfile}>
              {t.さっそく自己紹介カードをつくる}
            </PrimaryButton>
          </Link>
        </SectionContainer>
        <Spacer />
        <SectionContainer>
          <SectionHeader>{t.最近のゲーム募集}</SectionHeader>
          <BosyuIconCardsContainer>
            {homeBosyus.map((bosyu, bosyuIndex) => {
              return (
                <BosyuContainer key={bosyuIndex}>
                  <Link
                    href={`/bosyus/${bosyu.userId}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <BosyuItem isOpen={bosyu.status === 'open'}>
                      <BosyuImageContainer isOpen={bosyu.status === 'open'}>
                        <BosyuImage src={bosyu.profileImgUrl}></BosyuImage>
                        {bosyu.bosyuMemo.length > 0 ? (
                          <>
                            <BosyuMemoContainer>
                              <BosyuMemoImage
                                src={'/static/images/bosyu/icn_bosyu_memo.png'}
                              ></BosyuMemoImage>
                            </BosyuMemoContainer>
                          </>
                        ) : (
                          <></>
                        )}
                        <GameLogoContainer>
                          <Image
                            src={`https://storage.googleapis.com/nadare-production-static-images/static/images/gameTitles/icn_${bosyu.bosyuGameTitle}.png`}
                            alt={getGameTitleDisplayName(
                              bosyu.bosyuGameTitle.toString()
                            )}
                            width={20}
                            height={20}
                            style={{
                              overflow: 'hidden',
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                              objectFit: 'cover',
                            }}
                            unoptimized={true}
                            loading="lazy"
                          />
                        </GameLogoContainer>
                      </BosyuImageContainer>
                      {bosyu.bosyuGender === 'onlyFemale' && (
                        <BosyuGenderLabel>女の子のみ</BosyuGenderLabel>
                      )}
                      <BosyuTitle>
                        {bosyu.bosyuRanks.length > 0
                          ? getRankDisplayName(bosyu.bosyuRanks[0] as Rank)
                          : bosyu.bosyuGameModes.length > 0
                          ? getGameModeDisplayName(
                              bosyu.bosyuGameModes[0] as GameMode
                            )
                          : 'モードなんでも'}
                        @{bosyu.numOfRecruitingUsers}
                      </BosyuTitle>
                      <BosyuDescriptionText>
                        {platformText(
                          bosyu.bosyuPlatforms.length > 0
                            ? bosyu.bosyuPlatforms[0]
                            : 'any'
                        )}
                        {voiceChatText(bosyu.bosyuVC)}
                      </BosyuDescriptionText>
                      <BosyuCreateAtText suppressHydrationWarning>
                        {formatDistanceToNow(
                          fromUnixTime(bosyu.createAt.seconds),
                          {
                            locale: ja,
                            addSuffix: true,
                          }
                        )}
                      </BosyuCreateAtText>
                    </BosyuItem>
                  </Link>
                </BosyuContainer>
              )
            })}
          </BosyuIconCardsContainer>
          <ButtonDescriptionText>
            {t.サービスをご利用いただくにはSNSログインが必要です}
          </ButtonDescriptionText>
          <Link
            href={'/bosyus'}
            style={{
              textDecoration: 'none',
            }}
          >
            <CreateBosyuButton onClick={onClickCreateBosyu}>
              {t.さっそくゲーム募集をつくってみる}
            </CreateBosyuButton>
          </Link>
        </SectionContainer>
        <Spacer />
        <SectionContainer>
          <SectionHeader>{t.最近マッチしたルーム}</SectionHeader>
          <RoomsContainer>
            {homeRooms.map((room, roomIndex) => {
              return (
                <div key={roomIndex}>
                  <Link
                    href={`/bosyus/${room.hostUserId}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <RoomCard>
                      <RoomGameTitleContainer>
                        <Image
                          src={`https://storage.googleapis.com/nadare-production-static-images/static/images/gameTitles/icn_${room.gameTitle}.png`}
                          alt={getGameTitleDisplayName(
                            room.gameTitle.toString()
                          )}
                          width={16}
                          height={16}
                          unoptimized={true}
                          loading="lazy"
                          style={{
                            overflow: 'hidden',
                            borderRadius: '50%',
                            width: '16px',
                            height: '16px',
                            objectFit: 'cover',
                          }}
                        />
                        <RoomGameTitleText>
                          {getGameTitleDisplayName(room.gameTitle.toString())}
                        </RoomGameTitleText>
                      </RoomGameTitleContainer>
                      <RoomIconsBgContainer>
                        {room.matchedUserProfileImgUrls.map(
                          (matchedUserImageUrl, matchedImageUrlIndex) => {
                            return (
                              <RoomUserIcon key={matchedImageUrlIndex}>
                                <Image
                                  width={80}
                                  height={80}
                                  unoptimized={true}
                                  loading="lazy"
                                  style={{
                                    width: '80px',
                                    height: '80px',
                                    backgroundColor: colors.bg.tertiary,
                                  }}
                                  alt={matchedUserImageUrl}
                                  src={matchedUserImageUrl}
                                />
                              </RoomUserIcon>
                            )
                          }
                        )}
                      </RoomIconsBgContainer>
                      <OnlineBadge />
                      <RoomHorizontalContainer>
                        <Image
                          width={20}
                          height={20}
                          unoptimized={true}
                          loading="lazy"
                          src="/static/images/icn_bosyu.svg"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                          alt="ゲーム募集アイコン"
                        />
                        <RoomTitleText>
                          {room.matchedUserIds.length}人
                        </RoomTitleText>
                        <RoomDescriptionText>が参加</RoomDescriptionText>
                      </RoomHorizontalContainer>
                    </RoomCard>
                  </Link>
                </div>
              )
            })}
          </RoomsContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>{t.新着のユーザー}</SectionHeader>
          <HorizontalUsersContainer>
            {homeNewUsers.map((newUser, newUserIndex) => {
              return (
                <NewUserItem key={newUserIndex}>
                  <Link
                    href={`/users/${newUser.userId}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <UserImage>
                      <Image
                        style={{
                          backgroundColor: colors.bg.tertiary,
                        }}
                        width={78}
                        height={78}
                        unoptimized={true}
                        loading="lazy"
                        src={newUser.profileImgUrl}
                        alt={newUser.name}
                      />
                    </UserImage>
                    <UserNameText>{newUser.name}</UserNameText>
                    <UserDescriptionText>
                      {newUser.statement}
                    </UserDescriptionText>
                  </Link>
                </NewUserItem>
              )
            })}
          </HorizontalUsersContainer>
        </SectionContainer>
        <Spacer />
        <PickedItemsSectionContainer>
          <SectionHeader>{t.ピックアップグッズ}</SectionHeader>
          <PickedItemsDescriptionText>
            {
              t.運営が選んだ本当にいいと思えるゲームグッズだけをピックアップしています
            }
          </PickedItemsDescriptionText>
          <PickedItemsContainer>
            {homePickedItems.map((pickedItem) => {
              return (
                <div key={pickedItem.pickedItemId}>
                  <Link
                    href={pickedItem.url}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <PickedItemCard
                      onClick={() => {
                        logClickEvent('HomePickedItemClick', {
                          pickedItemId: pickedItem.pickedItemId,
                        })
                      }}
                    >
                      <PickedItemImage
                        alt={pickedItem.title}
                        src={pickedItem.imageUrl}
                      />
                      <PickedItemTextContent>
                        <PickedItemTitle>{pickedItem.title}</PickedItemTitle>
                        <PickedItemDescription>
                          {pickedItem.description}
                        </PickedItemDescription>
                      </PickedItemTextContent>
                    </PickedItemCard>
                  </Link>
                </div>
              )
            })}
          </PickedItemsContainer>
        </PickedItemsSectionContainer>
      </Container>
      <Footer />
    </div>
  )
}

export default Home

const db = getDb()

export const getHomeBosyus = async () => {
  const bosyuDocs = await db
    .collection(COLLECTION_NAME_BOSYUS)
    .orderBy('status', 'desc')
    .orderBy('createAt', 'desc')
    .limit(8)
    .get()

  if (bosyuDocs.empty) {
    return []
  } else {
    return bosyuDocs.docs.map((doc) => doc.data() as Bosyu)
  }
}

export const getHomeRooms = async () => {
  const homeRoomDocs = await db
    .collection(COLLECTION_NAME_ROOMS)
    .orderBy('createAt', 'desc')
    .limit(6)
    .get()

  if (homeRoomDocs.empty) {
    return []
  } else {
    return homeRoomDocs.docs.map((doc) => doc.data() as Room)
  }
}

export const getNewUsers = async () => {
  const snapshot = await db
    .collection(COLLECTION_NAME_USERS)
    .orderBy('createAt', 'desc')
    .limit(8)
    .get()

  if (snapshot.empty) {
    return []
  } else {
    return snapshot.docs.map((doc) => doc.data() as Users)
  }
}

export const getHomeProfiles = async () => {
  const snapshot = await db
    .collection(COLLECTION_NAME_PROFILES)
    .orderBy('createAt', 'desc')
    .limit(4)
    .get()

  if (snapshot.empty) {
    return []
  } else {
    return snapshot.docs.map((doc) => doc.data() as Profiles)
  }
}

export const getHomePickedItems = async () => {
  const newProfileDocs = await db
    .collection(COLLECTION_PICKED_ITEMS)
    .orderBy('createAt', 'desc')
    .limit(4)
    .get()

  if (newProfileDocs.empty) {
    return []
  } else {
    return newProfileDocs.docs.map((doc) => doc.data() as PickedItem)
  }
}

export const getServerSideProps: GetServerSideProps<Props> = async (req) => {
  const bosyus = await getHomeBosyus()
  const newUsers = await getNewUsers()
  const rooms = await getHomeRooms()
  const newProfiles = await getHomeProfiles()
  const pickedItems = await getHomePickedItems()
  return {
    props: {
      homeBosyus: JSON.parse(JSON.stringify(bosyus)),
      homeRooms: JSON.parse(JSON.stringify(rooms)),
      homeNewUsers: JSON.parse(JSON.stringify(newUsers)),
      homeNewProfiles: JSON.parse(JSON.stringify(newProfiles)),
      homePickedItems: JSON.parse(JSON.stringify(pickedItems)),
    },
  }
}

const Container = styled.div`
  color: ${colors.bg.primary};
  max-width: 800px;
  margin: 0 auto;
`

const FVContainer = styled.div`
  background-color: #e3b5bf;
  padding: 60px 0;
`

const LogoContainer = styled.div`
  margin-top: 10px;
  max-width: 360px;
  margin: 16px auto;
  width: 202px;
  height: 58px;
  overflow: hidden;

  @media (max-width: 500px) {
    max-width: 260px;
  }
`

const H1Text = styled.h1`
  text-align: center;
`

const H1TextSpan = styled.span`
  display: block;
  font-weight: bold;
  font-size: 17px;
  color: #fff;
`

const AboutGameeContainer = styled.div`
  display: flex;
  justify-content: center;
`

const AboutGamee = styled.div`
  background: #ffffff;
  border: 5px solid #b7d7ea;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  margin: 16px 16px auto;
  max-width: 420px;
`

const AboutGameeItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: ${colors.text.primary};
  &:not(:first-child) {
    margin-top: 16px;
  }
`

const ProfileTemplateItem = styled.div`
  width: 25%;
  padding: 4px;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    width: 50%;
  }
`

const BosyuContainer = styled.div`
  width: 88px;
`

const CreateBosyuButton = styled(PinkButton)`
  margin: 8px auto;
  width: 320px;
  height: 40px;
  line-height: 42px;
  font-size: 16px;
`

const PrimaryButton = styled(PinkButton)`
  margin: 8px auto;
  width: 320px;
  height: 40px;
  line-height: 42px;
  font-size: 16px;
`

const ButtonDescriptionText = styled.div`
  color: ${colors.text.secondary};
  text-align: center;
  font-size: 11px;
`

const Spacer = styled.div`
  margin-top: 50px;
`

const SectionContainer = styled.div`
  margin-top: 20px;
`

const PickedItemsSectionContainer = styled.div`
  background-color: #fff;
  padding: 16px;
`

const SectionHeader = styled.h2`
  margin-top: 20px;
  margin-left: 8px;
  color: ${colors.text.primary};
  font-weight: bold;
  font-size: 18px;
`

const GameTitlesContainer = styled.div``

const Attention = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  color: #fff;
`

const GameIconsContainer = styled.div`
  margin-top: 4px;
  margin-left: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GameIcon = styled.div<{ imgUrl: string }>`
  margin-right: -10px;
  background: center center / cover no-repeat url(${({ imgUrl }) => imgUrl});
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: visible;
`

const ProfileCardsContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`

const ProfileGameLogo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`

const ProfileGameLogoContainer = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`

const ProfileGameTitleText = styled.div`
  margin-left: 6px;
  color: ${colors.text.primary};
  font-size: 11px;
  font-weight: bold;
`

const ProfileCardItem = styled.div`
  width: 25%;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    width: 50%;
  }
`

const ProfileCard = styled.div`
  width: 95%;
  overflow: hidden;
  border-radius: 12px;
`

const PickedItemsContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`

const PickedItemsDescriptionText = styled.h3`
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  font-size: 12px;
  color: ${colors.text.secondary};
  line-height: 1.2;
`

const PickedItemCard = styled.div`
  margin-top: 30px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  width: 95%;
  overflow: hidden;
  border-radius: 12px;
`

const PickedItemImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`

const PickedItemTextContent = styled.div`
  margin-left: 16px;
`

const PickedItemTitle = styled.h1`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.text.primary};
  line-height: 1.2;
`

const PickedItemDescription = styled.h2`
  margin-top: 8px;
  font-size: 12px;
  color: ${colors.text.primary};
  line-height: 1.2;
`

const BosyuIconCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 30px;
`

const BosyuItem = styled.div<{ isOpen: boolean }>`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0.4')};
`

const BosyuGenderLabel = styled.h2`
  margin-top: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 4px;
  padding-left: 4px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  background: ${colors.lbl.quinary};
  border-radius: 12px;
  display: inline-block;
`

const BosyuTitle = styled.h2`
  margin-top: 12px;
  color: ${colors.text.primary};
  font-size: 13px;
  font-weight: bold;
  text-align: center;
`

const BosyuDescriptionText = styled.h2`
  margin-top: 6px;
  color: ${colors.text.secondary};
  text-align: center;
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
`

const BosyuCreateAtText = styled.time`
  margin-top: 6px;
  color: ${colors.text.secondary};
  text-align: center;
  font-size: 9px;
  text-align: center;
`

const BosyuImageContainer = styled.div<{
  isOpen: boolean
}>`
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff,
    0 0 0 6px ${({ isOpen }) => (isOpen ? '#2196F3' : '#dcdcdc')};
`

const BosyuImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

const BosyuMemoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
`

const BosyuMemoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

const GameLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
`

const UserImage = styled.div`
  width: 78px;
  height: 78px;
  overflow: hidden;
  border-radius: 50%;
  background: ${colors.bg.tertiary};
`

const UserNameText = styled.div`
  margin-top: 8px;
  color: ${colors.text.primary};
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
`

const UserDescriptionText = styled.div`
  margin-top: 6px;
  color: ${colors.text.secondary};
  text-align: center;
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
`

const RoomsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 30px;
`

const HorizontalUsersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 30px;
`

const RoomCard = styled.div`
  margin: auto;
  margin-bottom: 16px;
  padding: 12px;
  height: 160px;
  width: 120px;
  border-radius: 12px;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 10%);
  background: #fff;
`

const RoomGameTitleContainer = styled.div`
  display: flex;
`

const RoomGameTitleText = styled.div`
  margin: auto;
  margin-left: 6px;
  color: ${colors.text.primary};
  font-size: 11px;
  font-weight: bold;
  text-align: center;
`

const RoomIconsBgContainer = styled.div`
  margin: auto;
  margin-top: 12px;
  height: 80px;
  width: 80px;
  background: #edeef2;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-flow: wrap;
`

const OnlineBadge = styled.div`
  margin: auto;
  margin-top: -18px;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: #34c759;
  border: 3px solid #fff;
  border-radius: 50%;
`

const RoomUserIcon = styled.div`
  margin: auto;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RoomHorizontalContainer = styled.div`
  margin-top: 8px;
  margin-left: 4px;
  display: flex;
`

const RoomTitleText = styled.div`
  margin: auto;
  margin-left: 2px;
  color: ${colors.text.primary};
  font-size: 15px;
  font-weight: bold;
  text-align: center;
`

const RoomDescriptionText = styled.div`
  margin: auto;
  margin-left: -4px;
  color: ${colors.text.secondary};
  font-size: 11px;
  text-align: center;
`

const NewUserItem = styled.div`
  width: 88px;
  margin-bottom: 16px;
`
